:root {--loader-bg:#13ADEF;}
#MainLoader .expand { 
	width:100%; 
	height:3px; 
	background:var(--loader-bg); 
	position:absolute;
    -moz-animation:fullexpand 10s ease-out;
	-webkit-animation:fullexpand 10s ease-out;
	z-index:99999;
}

@-moz-keyframes fullexpand {
	0%  { width:0px;}
	100%{ width:100%;}	
}
@-webkit-keyframes fullexpand {
	0%  { width:0px;}
	100%{ width:100%;}	
}

.bubblingG {
text-align: center;
height:50px;
}

.bubblingG span {
display: inline-block;
vertical-align: middle;
width: 10px;
height: 10px;
margin: 25px auto;
background:var(--loader-bg);
-moz-border-radius: 50px;
-moz-animation: bubblingG 1.7s infinite alternate;
-webkit-border-radius: 50px;
-webkit-animation: bubblingG 1.7s infinite alternate;
-ms-border-radius: 50px;
-ms-animation: bubblingG 1.7s infinite alternate;
-o-border-radius: 50px;
-o-animation: bubblingG 1.7s infinite alternate;
border-radius: 50px;
animation: bubblingG 1.7s infinite alternate;
}

#bubblingG_1 {
-moz-animation-delay: 0s;
-webkit-animation-delay: 0s;
-ms-animation-delay: 0s;
-o-animation-delay: 0s;
animation-delay: 0s;
}

#bubblingG_2 {
-moz-animation-delay: 0.51s;
-webkit-animation-delay: 0.51s;
-ms-animation-delay: 0.51s;
-o-animation-delay: 0.51s;
animation-delay: 0.51s;
}

#bubblingG_3 {
-moz-animation-delay: 1.02s;
-webkit-animation-delay: 1.02s;
-ms-animation-delay: 1.02s;
-o-animation-delay: 1.02s;
animation-delay: 1.02s;
}

@-moz-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:var(--loader-bg);
		-moz-transform: translateY(0);
	}
	
	100% {
		width: 24px;
		height: 24px;
		background-color:#FFFFFF;
		-moz-transform: translateY(-21px);
	}

}

@-webkit-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:var(--loader-bg);
		-webkit-transform: translateY(0);
	}
	
	100% {
		width: 24px;
		height: 24px;
		background-color:#FFFFFF;
		-webkit-transform: translateY(-21px);
	}

}

@-ms-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:var(--loader-bg);
		-ms-transform: translateY(0);
	}

	100% {
		width: 24px;
		height: 24px;
		background-color:#FFFFFF;
		-ms-transform: translateY(-21px);
	}

}

@-o-keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:var(--loader-bg);
		-o-transform: translateY(0);
	}

	100% {
		width: 24px;
		height: 24px;
		background-color:#FFFFFF;
		-o-transform: translateY(-21px);
	}

}

@keyframes bubblingG {
	0% {
		width: 10px;
		height: 10px;
		background-color:var(--loader-bg);
		transform: translateY(0);
	}
	
	100% {
		width: 24px;
		height: 24px;
		background-color:#FFFFFF;
		transform: translateY(-21px);
	}
}

.loding {
	margin:0 auto;
	position:fixed;
	left:45%;
	top:40%;

	}
@media (min-width:200px) and (max-width:767px) {
.loding {
	left:30%;
	top:35%;

	}	
	}
@media (min-width:768px) and (max-width:991px) {
.loding {
	left:35%;
	top:40%;

	}	
	}		
.slim-loading-bar{position:fixed;margin:0;padding:0;top:0;left:0;right:0;z-index:99999}.slim-loading-bar-progress{margin:0;padding:0;z-index:99998;background-color:green;color:green;box-shadow:0 0 10px 0;height:2px;opacity:0;-webkit-transition:all .5s ease-in-out;transition:all .5s ease-in-out}